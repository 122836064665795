import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import LocalizedLink from '../LocalizedLink';
import './styles/index.scss';
import ErrorBanner from '../404';
import { gql, useQuery } from '@apollo/client';

const GET_PAGES = gql`
  query Pages {
    pages {
      id
      slug
      title
    }
  }
`;
const GET_CONTENT = gql`
  query PageContent($pageId: ID) {
    pageContent(pageId: $pageId) {
      id
      title
      content
    }
  }
`;
type PageItem = {
  id: number;
  title: string;
  slug: string;
};
type ContentItem = {
  id: number;
  title: string;
  content: string;
};
type Props = {
  pageSlug: string;
};

export default function Content({ pageSlug }: Props) {
  const [content, setContent] = useState('');
  const [error, setError] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState<number | null>(null);

  const {
    error: pagesError,
    data: pagesData,
  } = useQuery<{
    pages: PageItem[];
  }>(GET_PAGES);

  const pages = pagesData?.pages || [];

  const {
    error: contentError,
    data: contentData,
  } = useQuery<{
    pageContent: ContentItem;
  }>(GET_CONTENT, {
    variables: { pageId: selectedPageId },
  });

  useEffect(() => {
    if (pages && pageSlug) {
      const page = pages.find((p: PageItem) => p.slug === pageSlug);
      if (page) {
        setSelectedPageId(page.id);
      }
    }
  }, [pages, pageSlug]);

  useEffect(() => {
    if (contentData) {
      setContent(contentData.pageContent.content);
      setError(false);
    }
    if (contentError) {
      setError(true);
    }
  }, [contentData, contentError]);

  if (error || pagesError) {
    return <ErrorBanner />;
  }

  return (
    <div className="component-content">
      <div className="old-container">
        <div className="section">
          <div className="flex">
            <div className="sidebar">
              <ul className="sidebar-list">
                {pages.map((page) => (
                  <li key={page.id}>
                    <LocalizedLink
                      to={`/${page.slug}`}
                      className={pageSlug === page.slug ? 'active' : ''}
                    >
                      {page.title}
                    </LocalizedLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="right">{parse(content)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
